<template>
  <div>
    <b-modal
      ref="data"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.B0001')"
      :cancel-title="$t('button.B0005')"
      centered
      :title="propTitle"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      size="lg"
      hideHeaderClose
      no-close-on-backdrop
      no-close-on-esc
      body-class="bodyCustomEvent"
      footer-class="footerCustomEvent"
      :content-class="{ 'dark-custom': darkCheck }"
      :header-class="{ 'dark-header-custom': darkCheck }"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col>
              <video
                style="width: 100%; border: 1px solid black"
                autoplay
                controls="true"
                height="350px"
                poster="./tdt1.svg"
                playsinline
                :src="fileUrl"
              ></video>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col style="height: 30px">
              <b-badge
                class="mr-1"
                pill
                variant="light-info"
                style="font-size: 100%"
              >
                {{ eventData.occurTime }}
              </b-badge>

              <b-badge
                pill
                class="mr-1"
                style="font-size: 100%"
                variant="light-warning"
              >
                {{
                  eventData.direction === "up"
                    ? $t("label.L0019") +
                      " " +
                      Number(eventData.position).toFixed(2) * 1000
                    : $t("label.L0020") +
                      " " +
                      Number(eventData.position).toFixed(2) * 1000
                }}m
              </b-badge>
              <b-badge style="font-size: 100%" pill variant="light-success">
                {{ eventData.dEventTypeName }}
              </b-badge>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col v-show="eventData.tech == 'S'">
              <b-row
                ><label for="" class="mr-2">
                  {{ $t("label.L0049") }}</label
                ></b-row
              >
              <b-row>
                <b-button-group style="width: 100%">
                  <b-button
                    style="width: 20%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 11"
                    :pressed="eventData.rEventType == 11"
                    >{{ $t("label.L0091") }}</b-button
                  >
                  <b-button
                    style="width: 20%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 12"
                    :pressed="eventData.rEventType == 12"
                    >{{ $t("label.L0092") }}</b-button
                  >
                  <b-button
                    style="width: 20%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 13"
                    :pressed="eventData.rEventType == 13"
                    >{{ $t("label.L0093") }}</b-button
                  >
                  <b-button
                    style="width: 20%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 14"
                    :pressed="eventData.rEventType == 14"
                    >{{ $t("label.L0094") }}</b-button
                  >
                  <b-button
                    style="width: 20%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 0"
                    :pressed="eventData.rEventType == 0"
                    >{{ $t("label.L0095") }}</b-button
                  >
                </b-button-group>
              </b-row>
            </b-col>
            <b-col v-show="eventData.tech != 'S'">
              <b-row
                ><label for="" class="mr-2">
                  {{ $t("label.L0049") }}</label
                ></b-row
              >
              <b-row>
                <b-button-group style="width: 100%">
                  <b-button
                    style="width: 15%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 9"
                    :pressed="eventData.rEventType == 9"
                    >{{ $t("label.L0026") }}</b-button
                  >
                  <b-button
                    style="width: 15%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 4"
                    :pressed="eventData.rEventType == 4"
                    >{{ $t("label.L0025") }}</b-button
                  >
                  <b-button
                    style="width: 15%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 1"
                    :pressed="eventData.rEventType == 1"
                    >{{ $t("label.L0027") }}</b-button
                  >
                  <b-button
                    style="width: 15%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 3"
                    :pressed="eventData.rEventType == 3"
                    >{{ $t("label.L0023") }}</b-button
                  >
                  <b-button
                    style="width: 15%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 2"
                    :pressed="eventData.rEventType == 2"
                    >{{ $t("label.L0022") }}</b-button
                  >
                  <!-- <b-button
                    style="width: 15%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 6"
                    :pressed="eventData.rEventType == 6"
                    >{{ $t("label.L0024") }}</b-button
                  > -->

                  <b-button
                    style="width: 15%"
                    variant="outline-primary"
                    @click="eventData.rEventType = 0"
                    :pressed="eventData.rEventType == 0"
                    >{{ $t("label.L0095") }}</b-button
                  >
                </b-button-group>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-1" style="height: 40%">
            <b-col>
              <b-row
                ><label for="" style="width: 100%">
                  {{ $t("label.L0050") }}</label
                ></b-row
              >
              <b-row>
                <b-button-group style="width: 100%">
                  <!-- <b-button style="width:85px;" variant="outline-primary" @click="eventData.confirmType=0" :pressed="eventData.confirmType==0">미정</b-button> -->
                  <b-button
                    style="width: 25%"
                    variant="outline-primary"
                    @click="eventData.confirmType = 1"
                    :pressed="eventData.confirmType == 1"
                    >{{ $t("label.L0041") }}</b-button
                  >
                  <b-button
                    style="width: 25%"
                    variant="outline-primary"
                    @click="eventData.confirmType = 2"
                    :pressed="eventData.confirmType == 2"
                    >{{ $t("label.L0042") }}</b-button
                  >
                  <b-button
                    style="width: 25%"
                    variant="outline-primary"
                    @click="eventData.confirmType = 3"
                    :pressed="eventData.confirmType == 3"
                    >{{ $t("label.L0089") }}</b-button
                  >
                  <b-button
                    style="width: 25%"
                    variant="outline-primary"
                    @click="eventData.confirmType = 4"
                    :pressed="eventData.confirmType == 4"
                    >{{ $t("label.L0090") }}</b-button
                  >
                </b-button-group>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-1" style="height: 40%">
            <b-col>
              <b-form-group>
                <label for="">{{ $t("label.L0057") }}</label>
                <b-form-textarea
                  maxlength="500"
                  v-model="eventData.memo"
                  :class="{ 'custom-text': darkCheck }"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import EventBus from "@/event-bus";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BButtonGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";

export default {
  name: "equipPopup",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormValidFeedback,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButtonGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BBadge,
  },
  props: {
    propTitle: String,
    propType: String,
    darkCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gsGlobalTarget: {
        directionUp:
          this.$store.getters["settingConfig/getTarget"]("gs").direction.up,
        directionDown:
          this.$store.getters["settingConfig/getTarget"]("gs").direction.down,
      },
      fileUrl: "",
      eventData: {
        cameraId: "",
        confirmId: "",
        confirmType: 0,
        confirmTypeName: "",
        confirmed: false,
        dEventType: 0,
        dEventTypeName: "",
        distance: 0,
        elatitude: 0,
        elongitude: 0,
        elevation: 0,
        linkCode: "",
        linkCodeName: "",
        no: 0,
        occurTime: 0,
        occurTimeString: "",
        position: "",
        rEventType: 0,
        rEventTypeName: "",
        recImageUrl: "",
        recVideoUrl: "",
        rowNum: 0,
        tech: "",
        memo: "",
      },
      eventType: {
        999: () => this.$t("label.L0098"),
        0: () => this.$t("label.L0095"),
        1: () => this.$t("label.L0027"),
        2: () => this.$t("label.L0022"),
        3: () => this.$t("label.L0023"),
        4: () => this.$t("label.L0025"),
        // 6: () => this.$t("label.L0024"),
        9: () => this.$t("label.L0026"),
        // 11: () => this.$t("label.L0091"),
        // 12: () => this.$t("label.L0092"),
        // 13: () => this.$t("label.L0093"),
        // 14: () => this.$t("label.L0094"),
      },
      confirmType: {
        // 0: () =>  "미정",
        1: () => this.$t("label.L0041"),
        2: () => this.$t("label.L0042"),
        3: () => this.$t("label.L0089"),
        4: () => this.$t("label.L0090"),
      },
      eventStatusOptions: [
        {
          text: this.$t("label.L0023"),
          data: 3,
        },
        {
          text: this.$t("label.L0027"),
          data: 1,
        },
        {
          text: this.$t("label.L0022"),
          data: 2,
        },
        {
          text: this.$t("label.L0026"),
          data: 9,
        },
        {
          text: "돌발없음",
          data: 0,
        },
      ],
      resultOptions: [
        // {
        //   text:"미정",data:0
        // },
        {
          text: this.$t("label.L0041"),
          data: 1,
        },
        {
          text: this.$t("label.L0042"),
          data: 2,
        },
        {
          text: this.$t("label.L0089"),
          data: 3,
        },
        {
          text: this.$t("label.L0090"),
          data: 4,
        },
      ],
    };
  },
  created() {},
  methods: {
    async onBtnOk(data) {
      EventBus.$emit("demoEventPopupYn", true);
      data.preventDefault();
      await this.$emit("update-obj", this.eventData);
      this.$refs.data.hide();
    },
    onBtnCancel() {
      EventBus.$emit("demoEventPopupYn", true);
    },

    async updatepopup(data, url) {
      // this.fileUrl = url ? url : data.recVideoUrl;
      // this.fileUrl = dEventType;
      switch (data.popupCnt) {
        // case 1:
        //   this.fileUrl = require("@/assets/video/event/" + 20 + ".mp4");
        //   data.occurTime = this.getYesterdayDate() + " 07:50:15";
        //   break;
        // case 2:
        //   this.fileUrl = require("@/assets/video/event/" + 21 + ".mp4");
        //   data.occurTime = this.getYesterdayDate() + " 10:30:20";
        //   break;
        case 1:
          this.fileUrl = require("@/assets/video/event/" + 22 + ".mp4");
          data.occurTime = this.getYesterdayDate() + " 23:14:11";
          break;
        case 2:
          this.fileUrl = require("@/assets/video/event/" + 23 + ".mp4");
          data.occurTime = this.getYesterdayDate() + " 02:10:47";
          break;
        case 3:
          this.fileUrl = require("@/assets/video/event/" + 24 + ".mp4");
          data.occurTime = this.getYesterdayDate() + " 22:17:11";
          break;
        default:
          this.fileUrl = require("@/assets/video/event/" + 22 + ".mp4");
          data.occurTime = this.getYesterdayDate() + " 07:50:15";
          break;
      }
      await this.clearData();
      await this.setEventData(data);
      this.$refs.data.show();
    },
    getYesterdayDate() {
      const now = new Date();
      now.setDate(now.getDate() - 1); // 어제 날짜 계산

      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더함
      const day = String(now.getDate()).padStart(2, "0");
      //const hours = String(now.getHours()).padStart(2, "0");
      //const minutes = String(now.getMinutes()).padStart(2, "0");
      //const seconds = String(now.getSeconds()).padStart(2, "0");

      // 원하는 형식으로 반환 (2024-09-26 14:54:44)
      //return ${year}-${month}-${day} ${hours}:${minutes}:${seconds};
      return year + '-' + month+ '-' +day;
    },
    // async updatepopup(data,item) {
    //   this.loader = true;
    //   const returnUrl = await this.downLoadF(item);
    //   this.loader = false;
    //   this.fileUrl = returnUrl ? returnUrl : data.recVideoUrl;
    //   await this.clearData();
    //   await this.setEventData(data);
    //   this.$refs.data.show();
    // },
    // 정오검사 비디오 다운로드
    async downLoadF(item) {
      let fileUrl = "";

      try {
        const response = await this.downLoadFunction(item);

        if (response.data.success) {
          var data = response.data.data;
          let byteArray = data.byteArray; // 영상 byte
          var binaryString = window.atob(byteArray); // 바이트 코드를 디코드해 문자열로 변경
          var binaryLen = binaryString.length;
          var bytes = new Uint8Array(binaryLen); // 새 배열 생성
          for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i); //아스키코드 반환
            bytes[i] = ascii;
          }

          // const blob = new Blob([bytes, bytes], { type: "application/zip" });
          const blob = new Blob([bytes], { type: "video/mp4" });
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //   //IE : IE 브라우저인경우 실행 (다른 브라우저와 동작 방식이 다르기 때문 )
          //   window.navigator.msSaveBlob(blob, "WytestVideo");
          // } else {
          //   let link = document.createElement("a"); // 다운로드를 위한 가상 태그 생성
          //   link.href = window.URL.createObjectURL(blob); // 사용자가 파일에 접근할 수 있도록 Blob 객체에 대한 주소 생성
          //   link.setAttribute("download", "WytestVideo"); // 가상 태그에 다운로드 기능 추가
          //   link.click(); // 가상태그 클릭으로 파일에 접근해 다운로드
          //   link.remove(); // 가상 태그 삭제
          // }

          // fileUrl = await URL.createObjectURL(blob);
          fileUrl = response.config.params.recVideoUrl;
          // await  this.$refs.showPop.getUrlFunctio(fileUrl);
        } else {
          //  기존 dt 화면은 alert 창
          //  await  this.$refs.showPop.getUrlFunctio('');
        }
      } catch (err) {
        console.log("downLoadF Error :", err);
      }
      return fileUrl;
    },
    clearData() {
      this.eventData = {
        cameraId: "",
        confirmId: "",
        confirmType: 0,
        confirmTypeName: "",
        confirmed: false,
        dEventType: 0,
        dEventTypeName: "",
        distance: 0,
        elatitude: 0,
        elongitude: 0,
        elevation: 0,
        linkCode: "",
        linkCodeName: "",
        no: 0,
        occurTime: 0,
        occurTimeString: "",
        position: "",
        rEventType: 0,
        rEventTypeName: "",
        recImageUrl: "",
        recVideoUrl: "",
        rowNum: 0,
        tech: "",
        memo: "",
      };
    },
    setEventData(copyData) {
      this.eventData = { ...copyData };
      this.eventData.linkCodeName =
        this.eventData.direction + this.$t("label.L0087");
      this.eventData.dEventTypeName =
        this.eventType[this.eventData.dEventType]();
      // copyData.rEventType = copyData.rEventType!=null?copyData.rEventType:copyData.dEventType;

      // this.eventData.rEventType =  this.eventData.confirmType!=0
      //   ?  this.eventData.rEventType
      //   :  this.eventData.dEventType;
      // this.eventData.rEventTypeName = this.eventType[ this.eventData.rEventType]();
      // this.eventData.confirmType =  this.eventData.confirmType || 1; //0;

      this.eventData.occurTimeString =
        this.eventData.occurTime +
        " " +
        this.eventData.dEventTypeName +
        " - " +
        (this.eventData.linkCodeName == this.$t("label.L0097")
          ? this.$t("label.L0097") + this.eventData.position.toFixed(1)
          : this.$t("label.L0096") + this.eventData.position.toFixed(1)) +
        "k";

      //  eventData.linkCodeName=='시점 방면' ? "시점 방면 ":"종점 방면" }}{{eventData.position}}k
    },
    hideFunction() {
      this.$refs["data"].hide();
    },
    getUrlFunctio(url) {
      this.fileUrl = url ? url : "";
    },
  },
  mounted() {},
};
</script>
<style scoped>
label {
  color: #5e5873;
  font-size: 14px;
}
</style>
<style>
.bodyCustomEvent {
  padding: 1.4rem 1.4rem 0.2rem 1.4rem;
}
.footerCustomEvent {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 17px !important;
  padding-left: 2.1rem !important;
  padding-right: 2.1rem !important;
}
.dark-custom {
  background-color: #2f3134 !important;
  color: white !important;
}
.dark-custom label {
  color: white !important;
}
.dark-header-custom {
  background-color: #3c3d41 !important;
}
.dark-header-custom h5 {
  color: white !important;
}
.custom-text {
  background-color: #3c3d41 !important;
}
.dark-text {
  color: white;
}
.dark-custom .form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* 

::-webkit-scrollbar-thumb:hover {
  background: black !important;
}


::-webkit-scrollbar {
  width: 15px !important;
}

::-webkit-scrollbar-track {
  background-color:none;
  background: #e6e6e6 !important;
  border-left: 1px solid #dadada !important;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0 !important;
  border: solid 3px #e6e6e6 !important;
  border-radius: 7px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: black !important;
} */
</style>
